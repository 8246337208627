.container,
.content {
  height: 100%;
}
.body1StylepFont {
  font-size: 0.28rem;
}
.body1Style {
  min-height: calc(100% - 0.2rem);
  margin-top: 0.2rem;
  border-top-left-radius: 0.32rem;
  border-top-right-radius: 0.32rem;
  padding: 0.3rem 0.4rem;
  font-family: PingFangSC-Regular;
}
.bottom1 {
  margin-top: 2rem;
}
.whole {
  background: #ffffff;
}
.button {
  border: none !important;
  font-size: 0.24rem;
}
